import React from 'react'
import ConfirmComponent from '../components/ConfirmComponent'

const Confirm = () => {
  return (
    <div>
        <ConfirmComponent/>
    </div>
  )
}

export default Confirm