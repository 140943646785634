import React from 'react'
import Form from '../components/Form'
import "../components/Publics/css/index.css"

const FormData = () => {
  return (
    <div>
        <Form/>
    </div>
  )
}

export default FormData
