import React from 'react'
import Buiness from '../components/Buiness';
import "../components/Publics/css/index.css"

const BuinessPage = () => {
  return (
    <>
        <Buiness/>
    </>
  )
}

export default BuinessPage